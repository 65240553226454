import React from 'react'
import './Container.css'

const Container = ({ children, style }) => (
  <div className="container" style={style}>
    {children}
  </div>
)

export default Container
