import React from 'react'
import Container from './Container'

const Footer = () => (
  <footer>
    <Container style={{ marginTop: 80, marginBottom: 80 }}>
      Copyright 2018 - {new Date().getFullYear()}
    </Container>
  </footer>
)

export default Footer
