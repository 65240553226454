import React, { Fragment } from 'react'
import Navigation from './Navigation'
import Container from './Container'
import Footer from './Footer'
import Helmet from 'react-helmet'

const Layout = ({ children, noNav, metadata, title }) => (
  <Fragment>
    <Helmet>
      <title>{title ? `${title} | ${metadata.title}` : metadata.title}</title>
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@carsoncovell" />
      <meta property="og:url" content={metadata.url} />
      <meta name="og:title" content={title || metadata.title} />
      <meta name="description" content={metadata.description} />
      <meta
        name="keywords"
        content="javascript, web, developer, front-end, atlanta"
      />
      <link
        rel="shortcut icon"
        href="./favicon/favicon.ico"
        type="image/x-icon"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/static/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/static/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/static/favicon/favicon-16x16.png"
      />
      <link
        rel="mask-icon"
        href="/static/favicon/safari-pinned-tab.svg"
        color="#4A4A4A"
      />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>

    {!noNav && <Navigation />}
    <Container style={{ marginTop: noNav ? 40 : 0 }}>{children}</Container>
    <Footer />
  </Fragment>
)

export default Layout
