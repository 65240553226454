import React from 'react'
import { Link } from 'gatsby'
import './Navigation.css'
import Container from './Container'

const Navigation = () => (
  <nav style={{ marginTop: 40 }}>
    <Container>
      <Link className="link" to="/">
        <span role="img" aria-label="" style={{marginRight: '8px'}}>
          🏡
        </span>{' '}
        Home
      </Link>
      {/* <Link className="link" to="/writing">
        📝 Writing
      </Link> */}
    </Container>
  </nav>
)

export default Navigation
